import { HttpClient } from '@angular/common/http';
import { importExpr } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { GlobalVariables } from './global/global-variables';
// import axios from 'axios'
import { ServerService } from './global/components/services/server.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: ['']
})
export class AppComponent implements OnInit {
  user: SocialUser;
  loggedIn: boolean;
  constructor(
    private http: HttpClient,
    public SocialauthService: SocialAuthService,
    private router: Router,
    private globalVariables: GlobalVariables,
    private serverService: ServerService
  ) { }

  ngOnInit(): void {
    if (document.URL.indexOf('www.elisceo.com') !== -1) {
      window.location.href = document.URL.replace('www.', '')
    }


    this.SocialauthService.authState.subscribe(async (user) => {
      (user as any).idProvider = user.id
      console.log(user)
      this.serverService.register(user).subscribe(async (data) => {
        localStorage.setItem('TOKEN', data.token);
        localStorage.setItem('USER_DATAS', JSON.stringify(data.user))
        localStorage.setItem('BlockedLogin', 'false')
        Swal.fire('Sucesso', 'A ação foi executada com sucesso', 'success').then(() => window.location.reload());
      })
    })
  }
}
