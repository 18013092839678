import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { GlobalVariables } from '../../global-variables';

@Injectable({
  providedIn: 'root',
})
export class ServerService {

  constructor(private http: HttpClient, private globalVariables: GlobalVariables) { }


  register(user: any): Observable<any> {
    return this.http.post<any>(this.globalVariables.API.baseUrl + '/users/signup', user).pipe(
      map(obj => obj),
      catchError(e => this.errorHandler(e))
    )
  }

  errorHandler(e: any): Observable<any> {
    console.log(e.error)
    Swal.fire('Erro', 'Houve um erro ao criar o usuário, por favor tente novamente', 'error');
    return EMPTY;
  }

}
