import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';


/*APP IMPORTS*/
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

/*PAGES*/
import { NotFoundComponent } from './pages/not-found/not-found.component';

/*NETWORKING COMPONENTS*/
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { TestServerService } from './global/general/services/test-server/test-server.service';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { AutoCompleteModule } from 'primeng/autocomplete';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { GlobalVariables } from './global/global-variables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
export function getJwtToken(): string {
  return localStorage.getItem('JWT_TOKEN');
}

import { AngularFireStorageModule } from '@angular/fire/storage'
import { AngularFireModule } from '@angular/fire';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NgxSocialShareModule } from 'ngx-social-share';

export function getHttpTranslator(http: HttpClient): TranslateHttpLoader {

  return new TranslateHttpLoader(http);
}

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent
  ],
  imports: [
    NgxSocialShareModule,
    HttpClientModule,
    AutoCompleteModule,
    NgxDropzoneModule,
    GooglePlaceModule,
    SocialLoginModule,
    LazyLoadImageModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCGnMPNywi27r3uVEf-DtpXj424TTG5ZFI',
    }),
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyDC0sOEBjey-IEob38cTjzCNNp3cUVJjkY",
      authDomain: "elisceo-b7b5d.firebaseapp.com",
      projectId: "elisceo-b7b5d",
      storageBucket: "elisceo-b7b5d.appspot.com",
      messagingSenderId: "162704508103",
      appId: "1:162704508103:web:3e716cf8674e12a584eae2"
    }),
    AngularFireStorageModule,
    // HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getJwtToken,
        allowedDomains: ['localhost:4200']
      }
    }),


    // HttpClientInMemoryWebApiModule.forRoot(
    //   TestServerService, { dataEncapsulation: false, caseSensitiveSearch: true }
    // ),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: getHttpTranslator,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
    }),


    NgbModule,


  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    GlobalVariables,
    CookieService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '598517350014-hse9kkfco851pausok1lbar762kpiqb7.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('202231842553362')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
