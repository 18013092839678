import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from './pages/not-found/not-found.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./pages/home/home.module')
      .then(m => m.HomeModule)
  },
  {
    path: 'email-confirmation/:id',
    loadChildren: () => import('./pages/confirmation/email-confirmation/email-confirmation.module')
      .then(m => m.EmailConfirmationModule)
  },
  {
    path: 'course/:id',
    loadChildren: () => import('./pages/showcase/course-showcase/course-showcase.module')
      .then(m => m.CourseShowcaseModule)
  },
  {
    path: 'do/course/:id',
    loadChildren: () => import('./pages/showcase/do-course/do-course.module')
      .then(m => m.DoCourseModule)
  },
  {
    path: 'do/event/:id',
    loadChildren: () => import('./pages/showcase/do-event/do-event.module')
      .then(m => m.DoEventModule)
  },
  {
    path: 'create-course',
    loadChildren: () => import('./pages/creation/course-creation/course-creation.module')
      .then(m => m.CourseCreationModule)
  },
  {
    path: 'course-access',
    loadChildren: () => import('./pages/access/course-access/course-access.module')
      .then(m => m.CourseAccessModule)
  },
  { path: 'no-result', component: NotFoundComponent },
  {
    path: 'create-event',
    loadChildren: () => import('./pages/creation/event-creation/event-creation.module')
      .then(m => m.EventCreationModule)
  },
  {
    path: 'create-meeting',
    loadChildren: () => import('./pages/creation/meeting-creation/meeting-creation.module')
      .then(m => m.MeetingCreationModule)
  },
  {
    path: 'select-event-or-meeting',
    loadChildren: () => import('./pages/selection/event-meeting-selection/event-meeting-selection.module')
      .then(m => m.EventMeetingSelectionModule)
  },
  {
    path: 'event/:id', loadChildren: () => import('./pages/showcase/event-showcase/event-showcase.module')
      .then(m => m.EventShowcaseModule)
  },
  {
    path: 'event-access',
    loadChildren: () => import('./pages/access/event-access/event-access.module')
      .then(m => m.EventAccessModule)
  },
  {
    path: 'create-project',
    loadChildren: () => import('./pages/creation/project-creation/project-creation.module')
      .then(m => m.ProjectCreationModule)
  },
  {
    path: 'select-project-type',
    loadChildren: () => import('./pages/selection/project-type-selection/project-type-selection.module')
      .then(m => m.ProjectTypeSelectionModule)
  },
  {
    path: 'project/:id',
    loadChildren: () => import('./pages/showcase/project-showcase/project-showcase.module')
      .then(m => m.ProjectShowcaseModule)
  },
  {
    path: 'create-volunteering',
    loadChildren: () => import('./pages/creation/volunteering-creation/volunteering-creation.module')
      .then(m => m.VolunteeringCreationModule)
  },
  { // src/app/
    path: 'volunteering/:id',
    loadChildren: () => import('./pages/showcase/volunteering-showcase/volunteering-showcase.module')
      .then(m => m.VolunteeringShowcaseModule)
  },
  {
    path: 'access-volunteering',
    loadChildren: () => import('./pages/access/volunteering-access/volunteering-access.module').then(m => m.VolunteeringAccessModule)
  },
  {
    path: 'create-group',
    loadChildren: () => import('./pages/creation/group-creation/group-creation.module')
      .then(m => m.GroupCreationModule)
  },
  {
    path: 'edit-group/:id',
    loadChildren: () => import('./pages/edit/group-edit/group-edit.module')
      .then(m => m.GroupEditModule)
  },
  {
    path: 'edit-course/:id',
    loadChildren: () => import('./pages/edit/course-edit/course-edit.module')
      .then(m => m.CourseEditModule)
  },
  {
    path: 'group/:id',
    loadChildren: () => import('./pages/showcase/group-showcase/group-showcase.module')
      .then(m => m.GroupShowcaseModule)
  },
  {
    path: 'product/:id',
    loadChildren: () => import('./pages/showcase/product-showcase/product-showcase.module')
      .then(m => m.ProductShowcaseModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./pages/list-all/groups-all/groups-all.module')
      .then(m => m.GroupsAllModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/list-all/product-all/product-all.module')
      .then(m => m.ProductAllModule)
  },
  {
    path: 'courses',
    loadChildren: () => import('./pages/list-all/course-all/course-all.module')
      .then(m => m.CourseAllModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/list-all/events-all/events-all.module')
      .then(m => m.EventsAllModule)
  },
  {
    path: 'volunteers',
    loadChildren: () => import('./pages/list-all/volunteers-all/volunteers-all.module')
      .then(m => m.VolunteersAllModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/list-all/projects-all/projects-all.module')
      .then(m => m.ProjectsAllModule)
  },
  {
    path: 'netgrowing',
    loadChildren: () => import('./pages/netgrowing/netgrowing.module')
      .then(m => m.NetgrowingModule)
  },
  {
    path: 'create-ebook',
    loadChildren: () => import('./pages/creation/ebook-creation/ebook-creation.module')
      .then(m => m.EbookCreationModule)
  },
  {
    path: 'meet',
    loadChildren: () => import('./pages/meet/meet.module')
      .then(m => m.MeetModule)
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module')
      .then(m => m.ChatModule)
  },
  {
    path: ':username',
    loadChildren: () => import('./pages/profile/profile.module')
      .then(m => m.ProfileModule)
  },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
